import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import MyDropzone from '../../common/dropzone'
import axios from 'axios'
import url from '../../../globalVar'
import Spinner from 'react-spinners/RingLoader'
const override = `
display: block;
margin: 0 auto;
margin-top: 0px;
border-color: pink;
width: 48px;
height: 20px;
color: pink;
margin-left: 5px
`;
export class Digital_add_user extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            repatPassword: '',
            showError: false,
            admin: false,
            errorMsg: '',
            showSpinner: false,
            successMsg: ''
        }
        this.setField = this.setField.bind(this)
        this.submitHandler = this.submitHandler.bind(this)

    }


    setField(field, e) {
        console.log(e.target.value);
        this.setState({
            [field]: e.target.value
        })
    }
    onChangeHandler = event => {
        this.setState({
            thumbnail: Object.values(event.target.files)
        })
    }

    submitHandler = async () => {
        this.setState({
            showSpinner: true,
            successMsg: '',
            showError: false,
            errorMsg: '',
        })
        if (this.state.password != this.state.repatPassword) {
            this.setState({
                showError: true,
                errorMsg: 'Passwords dont Match',
                showSpinner: false
            })
            return
        }

        let data = {
            username: this.state.username,
            password: this.state.password,
            admin: this.state.admin
        }
        axios.post(url + '/users/',
            data,
            { headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('fila_admin_token') } })
            .then(res => {
                console.log(res);
                // this.props.history.push('/fundrisers/'+res.data.fundriser._id)
                if (res.data.success)
                    this.setState({
                        successMsg: 'User Added',
                        showSpinner: false,
                        username: '',
                        password: '',
                        repatPassword: ''
                    })
                else
                    this.setState({
                        showError: true,
                        errorMsg: res.data.error,
                        showSpinner: false

                    })


            })
        }
    onChange = (ev) => {
                    this.setState({
                        content: ev.editor.getData()
                    })
                }
    handleChange = (e) => {
                    console.log(e.target.value);
                    this.setState({
                        category: e.target.value
                    })
                    // this.setState({selectValue:e.target.value});
                }
    changeAdmin = () => {
                    this.setState({
                        admin: !this.state.admin
                    })
                }
    render() {
                return(
            <Fragment>
            <Breadcrumb title="Add Products" parent="Digital" />
            <div className="container-fluid">
                <div className="row product-adding">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>General</h5>
                            </div>
                            <div className="card-body">
                                <div className="digital-add needs-validation">



                                    <div className="form-group">
                                        <label className="col-form-label"><span>*</span> Username</label>
                                        <input value={this.state.username} onChange={this.setField.bind(null, 'username')} className="form-control" id="validationCustom02" type="text" required="" />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label"><span>*</span> Password</label>
                                        <input value={this.state.password} onChange={this.setField.bind(null, 'password')} className="form-control" id="validationCustom02" type="text" required="" />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label"><span>*</span> Repat Password</label>
                                        <input value={this.state.repatPassword} onChange={this.setField.bind(null, 'repatPassword')} className="form-control" id="validationCustom02" type="text" required="" />
                                    </div>
                                    <div className="form-group">
                                        <p>Admin:</p>
                                        <input type="checkbox" checked={this.state.admin} onChange={this.changeAdmin}></input>
                                    </div>
                                    {this.state.showError && <h5 style={{ color: 'red' }}>{this.state.errorMsg}</h5>}
                                    {this.state.successMsg != '' && <h5 style={{ color: 'lightgreen' }}>{this.state.successMsg}</h5>}


                                    <div style={{ display: 'flex' }}>
                                        <button onClick={this.submitHandler}>add user</button>
                                        {this.state.showSpinner && <Spinner
                                            css={override}
                                            size={26}
                                            color={"red"}
                                        />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            </Fragment>
        )
    }
}

export default Digital_add_user
