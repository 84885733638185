import React, { Component } from 'react'
import man from '../../../assets/images/dashboard/man.png'

export class User_panel extends Component {
    render() {
        return (
            <div>
                <div className="sidebar-user text-center">
                    <h6 className="mt-3 f-14">{localStorage.getItem('fila_admin_username')}</h6>
                </div>
            </div>
        )
    }
}

export default User_panel

