import {
    Home,
    Box,
    DollarSign,
    Tag,
    Clipboard,
    Camera,
    AlignLeft,
    UserPlus,
    Users,
    Chrome,
    BarChart,Settings,Archive, LogIn,LogOut
} from 'react-feather';

export const MENUITEMS = [
    // {
    //     path: '/dashboard', title: 'Dashboard', icon: Home, type: 'link', badgeType: 'primary', active: false
    // },
    {
        title: 'Products', icon: Box, type: 'sub', active: false, children: [
            { path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
            { path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
            // { path: '/products/digital/digital-edit-product', title: 'Edit Product', type: 'link' },
                
        ]
    },
    {
        title: 'Sales', icon: DollarSign, type: 'sub', active: false, children: [
            { path: '/sales/orders', title: 'Orders', type: 'link' },
        ]
    },
    {
        title: 'Add User',path:'/auth/adduser', icon: UserPlus, type: 'link', active: false
    },
    {
        title: 'Login',path:'/auth/login', icon: LogIn, type: 'link', active: false
    }
    ,
   
    {
        title: 'Log out',path:'/auth/login', icon: LogOut, type: 'button', active: false
    }
]
