import React, { Component, Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import './orders.styles.scss'
import url from '../../../globalVar'

export class Orders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            products: [],
            count: 0
        }
    }
    componentDidMount() {
        console.log('object');
            this.getAllProducts()
            this.props.history.push({
                pathname: '/products/digital/digital-product-list',
                search: '?page=1'
            });
    }
    getAllProducts = () =>{
        axios.get(url + '/products/all')
            .then(res => {
                console.log(res.data.count);
                console.log(res.data.data)
                this.setState({
                    products: res.data.data,
                    count: res.data.count
                })
            })
    }
    clickedProduct = (id) => {
        this.props.history.push('/products/digital/edit/' + id)

    }
    deleteOrder = async (id) => {
        let res = await axios.delete(url + '/products', { data: { id: id }, headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('fila_admin_token') } })
        this.getAllProducts()
    }
    handlePageChange = async data => {
        data.selected+=1
        this.props.history.push({
            pathname: '/products/digital/digital-product-list',
            search: '?page=' + data.selected
        });
       await axios.get(url + '/products/all?page='+data.selected)
            .then(res => {
                this.setState({
                    products: res.data.data,
                    count: res.data.count
                })
            })
    }
    render() {
        return (
            <Fragment>
                <Breadcrumb title="Orders" parent="Sales" />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Products</h5>
                                </div>
                                <div className="card-body order-datatable">
                                    <table id="orders">
                                        <tbody>
                                            <tr>
                                                <th>ID</th>
                                                <th>Product Name</th>
                                                <th>Price</th>
                                                <th>Delete</th>

                                            </tr>
                                            {this.state.products.map(el => (<tr key={el.id} onClick={() => { this.clickedProduct(el.id) }}>
                                                <td>{el.id}</td>
                                                <td>{el.productname}</td>
                                                <td>{el.price}</td>
                                                <td><button onClick={(e) => { e.stopPropagation(); this.deleteOrder(el.id) }}>Delete</button></td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <ReactPaginate pageCount={this.state.count / 10} pageRangeDisplayed={5} marginPagesDisplayed={5} onPageChange={this.handlePageChange}></ReactPaginate>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Orders
