import React, { Component, Fragment } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { User, Unlock } from 'react-feather';
import { withRouter } from 'react-router-dom';
import CurrentUserContext from '../../contexts/user.context'
import axios from 'axios'
import ClipLoader from "react-spinners/MoonLoader";
import url from '../../globalVar'

const override = `
display: block;
margin: 0 auto;
margin-top: 0px;
border-color: pink;
width: 48px;
height: 20px;
color: pink;
`;
export class LoginTabset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            currentUser: null,
            showError: false,
            showLoader: false
        }
        this.setField = this.setField.bind(this)

        this.handleChange = this.handleChange.bind(this)
    }

    clickActive = (event) => {
        document.querySelector(".nav-link").classList.remove('show');
        event.target.classList.add('show');
    }
    handleChange(date) {
        this.setState({
            startDate: date
        });
    }
    setField(field, e) {
        console.log(e.target.value);
        this.setState({
            [field]: e.target.value
        })
    }

    routeChange = () => {
        this.props.history.push(`${process.env.PUBLIC_URL}/products/digital/digital-add-product`);
    }
    clickedLogin = async () => {
        console.log('login');
        this.setState({
            showLoader: true
        })
        //   localStorage.setItem('fila_admin_username', this.state.username)
        //   console.log(localStorage);
        //   console.log(localStorage.getItem('fila_admin_username'));
        //   console.log(this.state.username+ ' '+ this.state.password);
        let res = await axios.post(url + '/users/login',
            {
                username: this.state.username,
                password: this.state.password
            }
        )
        console.log(res);
        if (res.data.token) {
            localStorage.setItem('fila_admin_username', res.data.username)
            localStorage.setItem('fila_admin_token', res.data.token)
            this.routeChange()
        }
        else{
            this.setState({
                showLoader: false,
                showError: true
            })
        }


    }
   
 
    render() {
        
        return (
            <div>
                <Fragment>

                    <Tabs>
                        <TabList className="nav nav-tabs tab-coupon" >
                            <Tab className="nav-link" onClick={(e) => this.clickActive(e)}><User />Login</Tab>
                        </TabList>

                        <TabPanel>
                            <div className="form-group">
                                <input value={this.state.username} onChange={this.setField.bind(null, 'username')} required="" name="login[username]" type="email" className="form-control" placeholder="Username" id="exampleInputEmail1" />
                            </div>
                            <div className="form-group">
                                <input value={this.state.password} onChange={this.setField.bind(null, 'password')} required="" name="login[password]" type="password" className="form-control" placeholder="Password" />
                            </div>
                            <div className="form-terms">
                                <div className="custom-control custom-checkbox mr-sm-2">
                                    <input type="checkbox" className="custom-control-input" id="customControlAutosizing" />

                                </div>
                            </div>
                            <div className="form-button">
                            {this.state.showError && <h5 style={{color: 'red'}}>wrong credentials</h5>}
                                <button className="btn btn-primary" type="submit" onClick={() => this.clickedLogin()}>{this.state.showLoader ? <ClipLoader
          css={override}
          size={16}
          color={"white"}
         
        /> :'Login'}</button>
                            </div>
                            {/* <div className="form-footer">
                                    <span>Or Login up with social platforms</span>
                                    <ul className="social">
                                        <li><a className="fa fa-facebook" href=""></a></li>
                                        <li><a className="fa fa-twitter" href=""></a></li>
                                        <li><a className="fa fa-instagram" href=""></a></li>
                                        <li><a className="fa fa-pinterest" href=""></a></li>
                                    </ul>
                                </div> */}
                        </TabPanel>

                    </Tabs>
                </Fragment>
            </div>
        )
    }
}

export default withRouter(LoginTabset)

