import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import MyDropzone from '../../common/dropzone'
import axios from 'axios'
import url from '../../../globalVar'
export class Digital_edit_pro extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content: 'hello world',
            productname: '',
            price: '',
            category: 'selected',
            link: '',
            text: '',
            image: '',
            // categories: ['Фрижидери', 'Комбинирани-Фрижидери', 'Вертикални-Замразнувачи', 'Хоризонтални-Замразнувачи', 'Електрични-Шпорети',
            //     'Шпорети-со-Керамички-Плотни', 'Комбинирани-Шпорети', 'Бојлери-за-купатило', 'Бојлери-за-Кујна', 'Мини-Шпорети', 'Микробранови-Печки', 'Електрични-Шпорети',
            //     'Вградни-Машини-за-Садови', 'Вградни-Микробранови-Печки', 'Вградни-Плотни', 'Вградни-Фурни', 'Вградни-Комплети', 'Машини-за-Перење', 'Машини-за-Сушење',
            //     'Машини-за-Садови', 'Инвертер-Системи', 'Сплит-Систем', 'Калорифери', 'Камини-на-дрва', 'Комбинирани-Котели', 'Панелни-Греалки', 'Печки-на-Пелет', 'Радијатори'
            //     , 'Шпорети', 'Правосмукалки', 'Миксери-/-Мултипрактик', 'Фритези', 'Сецко', 'Блендери', 'Апарати-за-Печење', 'Електрични-Тави', 'Решоа',
            //     'Решоа', 'Телевизори', 'Држачи-за-Телевизори', 'Кабли-и-Разделници', 'Smart-tv-боксови', 'Антени', 'Батерии', 'Далечински-управувачи'
            //     , 'Нега-на-Екрани', 'Велосипеди', 'Компјутери', 'Телефони', 'Фотоапарати-и-Камери'],
            categories: ['Аксесоари', 'Светла', 'Borner' ],

            showCategoryError: false
        }
        this.setField = this.setField.bind(this)
        this.submitHandler = this.submitHandler.bind(this)

    }
    setThumbnail = (thumb) => {
        // console.log(here);
    }
    setField(field, e) {
        console.log(e.target.value);
        this.setState({
            [field]: e.target.value
        })
    }
    onChangeHandler = event => {
        this.setState({
            thumbnail: Object.values(event.target.files)
        })
    }
    handleSubmit = (files, allFiles) => {
        this.setState({
            text: 'Adding...'
        })

        if (allFiles == 'done')
            this.setState({
                thumbnail: files.file,
                text: 'Success'
            })
        // allFiles.forEach(f => f.remove())
    }
    autofill = async () => {
        if (this.state.category == 'selected') {
            this.setState({
                showCategoryError: true
            })
            return
        }
        this.setState({
            text1: 'Adding...'
        })

        let data = {
            link: this.state.link,
            category: this.state.category
        }
        axios.post(url + '/products/autofill', data)
            .then(async result => {
                console.log(result);
                this.setState({
                    text1: 'Success'
                })
                let res = await axios.get(url + '/products/' + this.props.match.params.id)
                console.log(res.data[0]);
                this.setState({
                    productname: res.data[0].productname,
                    content: res.data[0].description,
                    category: res.data[0].category,
                    price: res.data[0].price,
                    image: res.data[0].image
                })
                // this.props.history.push('/fundrisers/'+res.data.fundriser._id)
            })
    }
    async componentDidMount() {
        let res = await axios.get(url + '/products/' + this.props.match.params.id)
        console.log(res.data[0]);
        this.setState({
            productname: res.data[0].productname,
            content: res.data[0].description,
            category: res.data[0].category,
            price: res.data[0].price,
            image: res.data[0].image
        })
    }
    submitHandler = async () => {
        let data = {
            id: this.props.match.params.id,
            productname: this.state.productname,
            description: this.state.content,
            category: this.state.category,
            price: this.state.price
        }
        console.log('object', data);
        debugger
        axios.put(url + '/products',
            data,
            { headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('fila_admin_token') } }
        )
            .then(res => {
                console.log(res.data.success);
                if (res.data.success)
                    window.location.href = '/products/digital/digital-product-list?page=1'
                else
                    window.location.href = '/auth/login'
                        
                // this.props.history.push('/fundrisers/'+res.data.fundriser._id)
            })
    }
    onChange = (ev) => {
        this.setState({
            content: ev.editor.getData()
        })
    }
    handleChange = (e) => {
        console.log(e.target.value);
        this.setState({
            category: e.target.value
        })
    }
    render() {
        return (
            <Fragment>
                <Breadcrumb title="Add Products" parent="Digital" />
                <div className="container-fluid">
                    <div className="row product-adding">
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-header">
                                    <h5>General Edit</h5>
                                </div>
                                <div className="card-body">
                                    {/* <div className="form-group">
                                        <label className="col-form-label pt-0"><span>*</span> Линк</label>
                                        <input value={this.state.link} onChange={this.setField.bind(null, 'link')} className="form-control" id="validationCustom01" type="text" required="" />
                                    </div>
                                    <button onClick={this.autofill}>autofill</button>
                                    {this.state.showCategoryError && <h5 className='category-error'>Select Category</h5>} */}

                                    <h4>{this.state.text1}</h4>
                                    <div className="digital-add needs-validation">
                                        <div className="form-group">
                                            <label className="col-form-label pt-0"><span>*</span> Име на Продуктот</label>
                                            <input value={this.state.productname} onChange={this.setField.bind(null, 'productname')} className="form-control" id="validationCustom01" type="text" required="" />
                                        </div>

                                        <div className="form-group">
                                            <label className="col-form-label pt-0"><span>*</span>Категорија</label>
                                            <br></br>
                                            <select name="categories" value={this.state.category} onChange={this.handleChange} className="categories">
                                                <option value={this.state.category}>{this.state.category}</option>
                                                {
                                                    this.state.categories.map(el => (
                                                        <option value={el}>{el}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label className="col-form-label"><span>*</span> Цена</label>
                                            <input value={this.state.price} onChange={this.setField.bind(null, 'price')} className="form-control" id="validationCustom02" type="text" required="" />
                                        </div>
                                        <label className="col-form-label"><span>*</span> Слики</label>
                                        <div>{
                                            this.state.image.split(',').map(el => (
                                                <img style={{ width: '50px', height: "50px", marginLeft: '10px' }} src={el}></img>
                                            ))}</div>
                                        <label className="col-form-label pt-0"> Product Upload</label>
                                        <MyDropzone submitFiles={this.handleSubmit} />
                                        <button onClick={this.submitHandler}>add product</button>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Add Description</h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation">
                                        <div className="form-group mb-0">
                                            <div className="description-sm">
                                                <CKEditors
                                                    activeclassName="p10"
                                                    content={this.state.content}
                                                    events={{
                                                        "blur": this.onBlur,
                                                        "afterPaste": this.afterPaste,
                                                        "change": this.onChange
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Digital_edit_pro
