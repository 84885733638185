import React, { Component, Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/orders';
import Datatable from '../common/datatable'
import axios from 'axios'
import './orders.styles.scss'
import url from '../../globalVar'

export class Orders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            products: []
        }
    }
    deleteOrder = async (id) => {
        console.log(id);
        let res = await axios.delete(url + '/products/cart', 
        { data: { id: id }, headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('fila_admin_token') } })
        console.log(res);
        this.getOrders()

    }
    completeOrder = async (id, complete) => {
        let res = await axios.post(url + '/products/cart/complete',
         { id: id, completed: !complete },
         { headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('fila_admin_token') } })
        this.getOrders()

    }
    componentDidMount() {
      this.getOrders()
    }
    getOrders = () =>{
        axios.get(url + '/products/orders')
        .then(res => {
            console.log(res)
            this.setState({
                products: res.data
            })
        })
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Orders" parent="Sales" />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Manage Order</h5>
                                </div>
                                <div className="card-body order-datatable">
                                    <table id="orders">
                                        <tbody>
                                            <tr>
                                            <th>Customer</th>
                                                <th>Phone</th>
                                                <th>Address</th>
                                                <th>Product Name</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                                <th>Delete</th>
                                                <th>Completed</th>
                                            </tr>
                                            {
                                                this.state.products.map(el => (<tr key={el.id}>
                                                    <td>{el.customername}</td>
                                                    <td>{el.phone}</td>
                                                    <td>{el.address}</td>
                                                    <td>{el.productname}</td>
                                                    <td>{el.quantity}</td>
                                                    <td>{el.price}</td>
                                                    <td><button onClick={() => { this.deleteOrder(el.id) }} >Delete</button></td>
                                                    <td><button onClick={() => { this.completeOrder(el.id, el.completed) }}>{el.completed ? 'Undo ' : 'Complete'}</button></td>

                                                </tr>

                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Orders
