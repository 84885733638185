import React, { Component, Fragment } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import MyDropzone from '../../common/dropzone'
import axios from 'axios'
import url from '../../../globalVar'
import Spinner from 'react-spinners/RingLoader'
const override = `
display: block;
margin: 0 auto;
margin-top: 0px;
border-color: pink;
width: 48px;
height: 20px;
color: pink;
margin-left: 5px
`;
export class Digital_add_pro extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content: 'hello world',
            productname: '',
            price: '',
            category: 'selected',
            link: '',
            text: '',
            image: '',
            showSpinner: false,
            showMsgText: 'Product Added',
            showMsg: false,
            showErrText: 'All fields are mandatory',
            showErrMsg: false,
            // categories: ['Фрижидери', 'Комбинирани-Фрижидери', 'Вертикални-Замразнувачи', 'Хоризонтални-Замразнувачи', 'Електрични-Шпорети',
            //     'Шпорети-со-Керамички-Плотни', 'Комбинирани-Шпорети', 'Бојлери-за-купатило', 'Бојлери-за-Кујна', 'Мини-Шпорети', 'Микробранови-Печки', 'Електрични-Шпорети',
            //     'Вградни-Машини-за-Садови', 'Вградни-Микробранови-Печки', 'Вградни-Плотни', 'Вградни-Фурни', 'Вградни-Комплети', 'Машини-за-Перење', 'Машини-за-Сушење',
            //     'Машини-за-Садови', 'Инвертер-Системи', 'Сплит-Систем', 'Калорифери', 'Камини-на-дрва', 'Комбинирани-Котели', 'Панелни-Греалки', 'Печки-на-Пелет', 'Радијатори'
            //     , 'Шпорети', 'Правосмукалки', 'Миксери-/-Мултипрактик', 'Фритези', 'Сецко', 'Блендери', 'Апарати-за-Печење', 'Електрични-Тави', 'Решоа',
            //     'Решоа', 'Телевизори', 'Држачи-за-Телевизори', 'Кабли-и-Разделници', 'Smart-tv-боксови', 'Антени', 'Батерии', 'Далечински-управувачи'
            //     , 'Нега-на-Екрани', 'Велосипеди', 'Компјутери', 'Телефони', 'Фотоапарати-и-Камери'],
            categories: ['Аксесоари', 'Светла', 'Borner', 'часовници', 'Тестатура', 'Глувчиња', 'Слушалки' ],
            showCategoryError: false
        }
        this.setField = this.setField.bind(this)
        this.submitHandler = this.submitHandler.bind(this)

    }
    componentDidMount() {
        console.log('mounted');
        console.log(url);
    }

    setThumbnail = (thumb) => {
        // console.log(here);
    }
    setField(field, e) {
        console.log('set field');
        console.log(e.target.value);
        this.setState({
            [field]: e.target.value
        })
    }
    onChangeHandler = event => {
        console.log('handler');
        this.setState({
            thumbnail: Object.values(event.target.files)
        })
    }
    handleSubmit = (files, allFiles) => {
        console.log('drop', allFiles);
        this.setState({
            text: 'Adding...',
        })

        if (allFiles == 'done')
            this.setState({
                thumbnail: files.file,
                text: 'Success'
            })
            console.log(files);
        // allFiles.forEach(f => f.remove())
    }
    autofill = async () => {
        this.setState({
            showErrMsg: false,
            showMsg: false
        })
        if (this.state.category == 'selected') {
            this.setState({
                showCategoryError: true
            })
            return
        }
        this.setState({
            text1: 'Adding...',
            showCategoryError: false
        })

        let data = {
            link: this.state.link,
            category: this.state.category
        }
        let result = await axios.post(url + '/products/autofill', 
        data,
        { headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('fila_admin_token') } }
        )
        console.log(result);
        this.setState({
            text1: 'Success'
        })
        let res = await axios.get(url + '/products/' + result.data.id)
        console.log(res.data[0]);
        this.setState({
            productname: res.data[0].productname,
            content: res.data[0].description,
            category: res.data[0].category,
            price: res.data[0].price,
            image: res.data[0].image 
        })
    }
    submitHandler = async () => {
        this.setState({
            showErrMsg: false,
            showMsg: false
        })
        if (this.state.category == 'selected' || this.state.productname == '' || this.state.price == '') {
            console.log('object');
            this.setState({
                showErr: true
            })
            return
        }
        this.setState({
            showSpinner: true
        })
        const data = new FormData()
        // this.state.thumbnail.forEach(el =>{
        //     console.log(el);
        //     data.append('upload',el)
        // })
        data.append('upload', this.state.thumbnail)
        data.append('productname', this.state.productname)
        data.append('description', this.state.content)
        data.append('price', this.state.price)
        data.append('category', this.state.category)

        console.log(data);
        axios.post(url + '/products',
            data,
            { headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('fila_admin_token') } }
        )
            .then(res => {
                console.log(res);
                this.setState({
                    showSpinner: false,
                    showMsg: true,
                    showErr: false,
                    productname: '',
                    price: '',
                    category: 'selected',
                    content: 'hello world'
                })
                // this.props.history.push('/fundrisers/'+res.data.fundriser._id)
            })
    }
    onChange = (ev) => {
        console.log(ev.editor.getData());
        this.setState({
            content: ev.editor.getData()
        })
    }
    handleChange = (e) => {
        console.log(e.target.value);
        this.setState({
            category: e.target.value
        })
        // this.setState({selectValue:e.target.value});
    }
    render() {
        return (
            <Fragment>
                <Breadcrumb title="Add Products" parent="Digital" />
                <div className="container-fluid">
                    <div className="row product-adding">
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-header">
                                    <h5>General</h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation">
                                        <div className="form-group">
                                            <label className="col-form-label pt-0"><span>*</span> Линк</label>
                                            <input value={this.state.link} onChange={this.setField.bind(null, 'link')} className="form-control" id="validationCustom01" type="text" required="" />
                                        </div>
                                        <button onClick={this.autofill}>autofill</button>
                                        {this.state.showCategoryError && <h5 className='category-error'>Select Category</h5>}
                                        <h4>{this.state.text1}</h4>
                                        <div className="form-group">
                                            <label className="col-form-label pt-0"><span>*</span> Име на Продуктот</label>
                                            <input value={this.state.productname} onChange={this.setField.bind(null, 'productname')} className="form-control" id="validationCustom01" type="text" required="" />
                                        </div>

                                        <div className="form-group">
                                            <label className="col-form-label pt-0"><span>*</span>Категорија</label>
                                            <br></br>
                                            <select name="categories" value={this.state.category} onChange={this.handleChange} className="categories">
                                                <option value={'selected'}>{'select category'}</option>
                                                {
                                                    this.state.categories.map(el => (
                                                        <option value={el}>{el}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>


                                        <div className="form-group">
                                            <label className="col-form-label"><span>*</span> Цена</label>
                                            <input value={this.state.price} onChange={this.setField.bind(null, 'price')} className="form-control" id="validationCustom02" type="text" required="" />
                                        </div>
                                        {this.state.image != '' && <div>
                                        <label className="col-form-label"><span>*</span> Слики</label>
                                         {
                                            this.state.image.split(',').map(el => (
                                                <img style={{ width: '50px', height: "50px", marginLeft: '10px' }} src={el}></img>
                                            ))}</div>}
                                        <label className="col-form-label pt-0"> Product Upload</label>
                                        <MyDropzone submitFiles={this.handleSubmit} />
                                        <div style={{ display: 'flex' }}>
                                            <button disabled={this.state.text === 'Adding...'} onClick={this.submitHandler}>add product</button>
                                            {this.state.showSpinner && <Spinner
                                                css={override}
                                                size={26}
                                                color={"red"}
                                            />}
                                        </div>
                                        {this.state.showMsg && <h3 style={{ color: 'lightgreen' }}>{this.state.showMsgText}</h3>}
                                        {this.state.showErr && <h3 style={{ color: 'red' }}>{this.state.showErrText}</h3>}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Add Description</h5>
                                </div>
                                <div className="card-body">
                                    <div className="digital-add needs-validation">
                                        <div className="form-group mb-0">
                                            <div className="description-sm">
                                                <CKEditors
                                                    activeclassName="p10"
                                                    content={this.state.content}
                                                    events={{
                                                        "blur": this.onBlur,
                                                        "afterPaste": this.afterPaste,
                                                        "change": this.onChange
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Digital_add_pro
